const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

const PlAYER_STORAGE_KEY = 'F8_PLAYER';

const player = $('.player');
const cd = $('.cd');
const heading = $('header h2');
const cdThumb = $('.cd-thumb');
const audio = $('#audio');
const playBtn = $('.btn-toggle-play');
// const progress = $("#progress");
const prevBtn = $('.btn-prev');
const nextBtn = $('.btn-next');
const randomBtn = $('.btn-random');
// const repeatBtn = $('.btn-repeat');
const playlist = $('.playlist');

const app = {
  currentIndex: 0,
  isPlaying: false,
  isRandom: false,
  // isRepeat: false,
  config: {},
  // (1/2) Uncomment the line below to use localStorage
  // config: JSON.parse(localStorage.getItem(PlAYER_STORAGE_KEY)) || {},
  songs: [
    {
      name: 'Pop',
      singer: '',
      path: 'https://preciousradio.net/proxy/pop/stream',
      image: '/assets/images/radios/pop.png',
      cover: '/assets/images/radios/pop_cover.webp',
    },
    {
      name: 'Mood',
      singer: '',
      path: 'https://preciousradio.net/proxy/mood/stream',
      image: '/assets/images/radios/mood.png',
      cover: '/assets/images/radios/mood_cover.webp',
    },
    {
      name: 'Funk',
      singer: '',
      path: 'https://preciousradio.net/proxy/funk/stream',
      image: '/assets/images/radios/funk.png',
      cover: '/assets/images/radios/funk_cover.webp',
    },
    {
      name: 'Disco',
      singer: '',
      path: 'https://preciousradio.net/proxy/disco/stream',
      image: '/assets/images/radios/disco.png',
      cover: '/assets/images/radios/disco_cover.webp',
    }, {
      name: 'Hip-Hop',
      singer: '',
      path: 'https://preciousradio.net/proxy/hiphop/stream',
      image: '/assets/images/radios/hiphop.png',
      cover: '/assets/images/radios/hiphop_cover.webp',
    }, {
      name: 'Eighties',
      singer: '',
      path: 'https://preciousradio.net/proxy/eighties/stream',
      image: '/assets/images/radios/eighties.png',
      cover: '/assets/images/radios/eighties_cover.webp',
    }, {
      name: 'CoolJam',
      singer: '',
      path: 'https://preciousradio.net/proxy/cooljams/stream',
      image: '/assets/images/radios/cooljams.png',
      cover: '/assets/images/radios/cooljams_cover.webp',
    }, {
      name: 'Dream',
      singer: '',
      path: 'https://preciousradio.net/proxy/dream/stream',
      image: '/assets/images/radios/dream.png',
      cover: '/assets/images/radios/dream_cover.webp',
    }, {
      name: 'Love',
      singer: '',
      path: 'https://preciousradio.net/proxy/love/stream',
      image: '/assets/images/radios/love.png',
      cover: '/assets/images/radios/love_cover.webp',
    },
  ],
  setConfig(key, value) {
    this.config[key] = value;
    // (2/2) Uncomment the line below to use localStorage
    // localStorage.setItem(PlAYER_STORAGE_KEY, JSON.stringify(this.config));
  },
  render() {
    const htmls = this.songs.map((song, index) => `
                        <div class="song ${
  index === this.currentIndex ? 'active' : ''
}" data-index="${index}">
                            <div class="thumb"
                                style="background-image: url('${song.cover}')">
                            </div>
                        </div>
                    `);
    playlist.innerHTML = htmls.join('');
  },
  defineProperties() {
    Object.defineProperty(this, 'currentSong', {
      get() {
        return this.songs[this.currentIndex];
      },
    });
  },
  handleEvents() {
    const _this = this;
    const cdWidth = cd.offsetWidth;

    // Handle CD spins / stops
    const cdThumbAnimate = cdThumb.animate([{ transform: 'scale(.95, .95)' }], {
      duration: 1500, // 10 seconds
      iterations: Infinity,
    });
    cdThumbAnimate.pause();

    // Handles CD enlargement / reduction
    // document.onscroll = function () {
    //   const scrollTop = window.scrollY || document.documentElement.scrollTop;
    //   const newCdWidth = cdWidth - scrollTop;

    //   cd.style.width = newCdWidth > 0 ? newCdWidth + "px" : 0;
    //   cd.style.opacity = newCdWidth / cdWidth;
    // };

    // Handle when click play
    playBtn.onclick = function () {
      if (_this.isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
    };

    // When the song is played
    audio.onplay = function () {
      _this.isPlaying = true;
      player.classList.add('playing');
      cdThumbAnimate.play();
    };

    // When the song is pause
    audio.onpause = function () {
      _this.isPlaying = false;
      player.classList.remove('playing');
      cdThumbAnimate.pause();
    };

    // When the song progress changes
    // audio.ontimeupdate = function () {
    //   if (audio.duration) {
    //     const progressPercent = Math.floor(
    //       (audio.currentTime / audio.duration) * 100
    //     );
    //     progress.value = progressPercent;
    //   }
    // };

    // Handling when seek
    // progress.onchange = function (e) {
    //   const seekTime = (audio.duration / 100) * e.target.value;
    //   audio.currentTime = seekTime;
    // };

    // When next song
    nextBtn.onclick = function () {
      if (_this.isRandom) {
        _this.playRandomSong();
      } else {
        _this.nextSong();
      }
      audio.play();
      _this.render();
      _this.scrollToActiveSong();
    };

    // When prev song
    prevBtn.onclick = function () {
      if (_this.isRandom) {
        _this.playRandomSong();
      } else {
        _this.prevSong();
      }
      audio.play();
      _this.render();
      _this.scrollToActiveSong();
    };

    // Handling on / off random song
    randomBtn.onclick = function (e) {
      _this.isRandom = !_this.isRandom;
      _this.setConfig('isRandom', _this.isRandom);
      randomBtn.classList.toggle('active', _this.isRandom);
    };

    // // Single-parallel repeat processing
    // repeatBtn.onclick = function (e) {
    //   _this.isRepeat = !_this.isRepeat;
    //   _this.setConfig('isRepeat', _this.isRepeat);
    //   repeatBtn.classList.toggle('active', _this.isRepeat);
    // };

    // // Handle next song when audio ended
    // audio.onended = function () {
    //   if (_this.isRepeat) {
    //     audio.play();
    //   } else {
    //     nextBtn.click();
    //   }
    // };

    // Listen to playlist clicks
    playlist.onclick = function (e) {
      const songNode = e.target.closest('.song:not(.active)');

      if (songNode || e.target.closest('.option')) {
        // Handle when clicking on the song
        if (songNode) {
          _this.currentIndex = Number(songNode.dataset.index);
          _this.loadCurrentSong();
          _this.render();
          audio.play();
        }

        // Handle when clicking on the song option
        if (e.target.closest('.option')) {
        }
      }
    };
  },
  scrollToActiveSong() {
    setTimeout(() => {
      $('.song.active').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }, 300);
  },
  loadCurrentSong() {
    heading.textContent = this.currentSong.name;
    cdThumb.style.backgroundImage = `url('${this.currentSong.image}')`;
    audio.src = this.currentSong.path;
  },
  loadConfig() {
    this.isRandom = this.config.isRandom;
    // this.isRepeat = this.config.isRepeat;
  },
  nextSong() {
    this.currentIndex++;
    if (this.currentIndex >= this.songs.length) {
      this.currentIndex = 0;
    }
    this.loadCurrentSong();
  },
  prevSong() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.songs.length - 1;
    }
    this.loadCurrentSong();
  },
  playRandomSong() {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * this.songs.length);
    } while (newIndex === this.currentIndex);

    this.currentIndex = newIndex;
    this.loadCurrentSong();
  },
  start() {
    // Assign configuration from config to application
    this.loadConfig();

    // Defines properties for the object
    this.defineProperties();

    // Listening / handling events (DOM events)
    this.handleEvents();

    // Load the first song information into the UI when running the app
    this.loadCurrentSong();

    // Render playlist
    this.render();

    // Display the initial state of the repeat & random button
    randomBtn.classList.toggle('active', this.isRandom);
    // repeatBtn.classList.toggle('active', this.isRepeat);
  },
};

app.start();
